"use client";

import { preload, SWRConfig } from "swr";

const fetcher = async (input: RequestInfo, init?: RequestInit) => {
  const res = await fetch(input, init);
  return res.json();
};

// NOTE: 初期レンダリングを高速化するためアプリケーション全体で用いるマスタデータのプリロードを行う
// FIXME: ステージング環境でエラーが多発するためコメントアウト
// const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL || "http://localhost:3000";

// preload(`${baseUrl}/api/makers`, fetcher);
// preload(`${baseUrl}/api/brands`, fetcher);
// preload(`${baseUrl}/api/categories`, fetcher);

export const SWRProvider = ({ children }: { children: React.ReactNode }) => {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>;
};

export default SWRProvider;
